
import { defineComponent, ref, onMounted, h } from 'vue'
import { NDataTable, NTag } from 'naive-ui'
import { useGrupos } from '@/store'
import { TableColumns } from 'naive-ui/lib/data-table/src/interface'
import { IMAGE_URL } from '@/config'
import ModalEditGrupo from '@/views/grupos/modals/ModalEditGrupo.vue'
import ModalHistoricGrupoVue from '@/views/grupos/modals/ModalHistoricGrupo.vue'

export default defineComponent({
  components: { NDataTable },
  setup() {
    const grupos = useGrupos()

    function getColumns() {
      return ref<TableColumns<any>>([
        {
          title: 'Ícone',
          key: 'imagem',

          render: (row) => {
            return h(
              'div',
              {
                style: {
                  padding: '5px',
                  backgroundColor: '#001428',
                  borderRadius: '3%',
                  width: '55px',
                  height: '55px',
                },
              },
              h('img', {
                src: IMAGE_URL + row.imagem,
                style: {
                  width: '50px',
                  height: '50px',
                },
              })
            )
          },
        },
        {
          title: 'Nome',
          key: 'titulo',
        },
        {
          title: 'Status',
          key: 'status',
          render(row) {
            return h(
              NTag,
              {
                style: {
                  marginRight: '6px',
                },
                type: row.status == 1 ? 'success' : 'error',
              },
              {
                default: () => {
                  if (row.status == 1) {
                    return 'Ativo'
                  } else {
                    return 'Inativo'
                  }
                },
              }
            )
          },
        },
        {
          title: 'Ações',
          key: 'acoes',
          align: 'right',
          render: (row) => {
            return h('div', { class: 'space-x-2 justify-end' }, [
              h(ModalEditGrupo, {
                grupo: row,
                emitUpdate: () => {
                  grupos.fetch()
                },
              }),
              h(ModalHistoricGrupoVue, {
                grupo: row,
              }),
            ])
          },
        },
      ])
    }

    onMounted(async () => {
      await grupos.fetch()
    })

    return {
      grupos,
      columns: getColumns(),
    }
  },
})
