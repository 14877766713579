
import { defineComponent, ref } from 'vue'
import {
  useMessage,
  NButton,
  NModal,
  NForm,
  NFormItem,
  NInput,
  NIcon,
  NSwitch,
} from 'naive-ui'
import { API } from '@/api/api'
import { CreateOutline } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    NButton,
    NModal,
    NForm,
    NFormItem,
    NInput,
    NIcon,
    CreateOutline,
    NSwitch,
  },
  props: {
    grupo: {
      type: Object,
      required: true,
    },
    emitUpdate: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const message = useMessage()
    const showModal = ref(false)
    const model = ref({
      titulo: null,
      status: true,
    })

    async function updateGrupo() {
      try {
        const data = {
          ...model.value,
        }

        const response = await API().put(
          `/dashboard/grupos/${props.grupo.id}`,
          data
        )

        if (response.status === 200) {
          message.success('Grupo atualizado com sucesso!')
          props.emitUpdate()
          showModal.value = false
        } else {
          message.error(
            'Erro ao atualizar grupo, verifique se os dados estão corretos e tente novamente mais tarde'
          )
        }
      } catch (error) {
        console.error(error)
        message.error(
          'Erro interno - Tente novamente mais tarde e contate o suporte.'
        )
      }
    }

    function openModal() {
      showModal.value = true
      model.value = {
        titulo: props.grupo.titulo,
        status: props.grupo.status == 1 ? true : false,
      }
    }

    return {
      model,
      showModal,
      updateGrupo,
      openModal,
    }
  },
})
