import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_data_table = _resolveComponent("n-data-table")!

  return (_openBlock(), _createBlock(_component_n_data_table, {
    remote: "",
    ref: "table",
    columns: _ctx.columns,
    data: (_ctx.grupos.data as any),
    loading: _ctx.grupos.isLoading,
    pagination: _ctx.grupos.pagination,
    "onUpdate:page": _ctx.grupos.handlePageChange,
    "onUpdate:pageSize": _ctx.grupos.handlePageSizeChange
  }, null, 8, ["columns", "data", "loading", "pagination", "onUpdate:page", "onUpdate:pageSize"]))
}